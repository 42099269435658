.box {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.frame-wrapper {
  border: 0px none;
  height: 25px;
  width: 121px;
}

.frame {
  align-items: flex-start;
  background-color: #eaebec;
  border-radius: 2px;
  background: var(--neutrals-gray-75, #eaebec);
  display: flex;
  gap: 8px;
  left: 0;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  top: 0;
  width: fit-content;
}

.valid-frame {
  align-items: flex-start;
  background-color: #eaebec;
  border-radius: 2px;
  background: var(--error-green-25, #bff7d6);
  display: flex;
  gap: 8px;
  left: 0;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  top: 0;
  width: fit-content;
}

.invalid-frame {
  align-items: flex-start;
  background-color: #eaebec;
  border-radius: 2px;
  background: var(--error-red-25, #ffe3e5);
  display: flex;
  gap: 8px;
  left: 0;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  top: 0;
  width: fit-content;
}

.icon-frame {
  border: 0px none;
  height: 24px;
}

.valid-icon-frame {
  border: 0px none;
  height: 24px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 3px;
  padding-bottom: 2px;
  background: var(--error-green-25, #bff7d6);
}

.invalid-icon-frame {
  border: 0px none;
  height: 24px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 3px;
  padding-bottom: 2px;
  background: var(--error-red-25, #ffe3e5);
}

.label {
  color: #344053;
  font-family: 'OpenSans-SemiBold';
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.valid-label {
  color: #09693e;
  font-family: 'OpenSans-SemiBold';
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.invalid-label {
  color: #9e0901;
  font-family: 'OpenSans-SemiBold';
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
