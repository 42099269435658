.exchage-card-points-container {
  display: flex;
  gap: 1.5px;
  font-family: OpenSans-Bold;
  font-size: 16px;
  line-height: 21px;
  color: #783184;
  height: 24px;
}

.exchage-card-points-lenght-plus-13-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.exchage-card-points-lenght-less-13-container {
  display: flex;
  width: 100%;
}

.exchage-card-points-lenght-plus-13 {
  display: flex;
  flex-direction: row;
  gap: 2px;
  width: 100%;
}

.exchage-card-points-lenght-less-13 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  gap: 2px;
  width: 100%;
}
