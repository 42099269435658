.exchange-badge-prod {
  width: fit-content;
  font-family: OpenSans-Bold;
  font-size: 14px;
  padding: 2px 4px;
}

.exchange-badge-prod.online {
  color: #87318b;
  background-color: #87318b1a;
}
.exchange-badge-prod.paris {
  color: #006dff;
  background-color: #dbe8f8;
}
.exchange-badge-prod.easy {
  color: #df1122;
  background-color: #fff6ed;
}
.exchange-badge-prod.jumbo {
  color: #088921;
  background-color: #e5f7e3;
}
.exchange-badge-prod.sisa {
  color: #df0f20;
  background-color: #fef3f2;
}
.exchange-badge-prod.out-stock {
  color: rgba(102, 112, 133, 1);
  background: rgba(234, 235, 236, 1);
}
