.store-button-v2-container-default {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.store-button-v2-default {
  border-radius: 12px;
  border: 1px solid #dbdee5;
  background: #fff;
  min-width: 180px;
  box-shadow: 0px 2.259px 2.214px 0px rgba(0, 0, 0, 0.01),
    0px 3.726px 5.32px 0px rgba(0, 0, 0, 0.02),
    0px 5.586px 10.017px 0px rgba(0, 0, 0, 0.03),
    0px 8.726px 17.869px 0px rgba(0, 0, 0, 0.03);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  height: 60px;
  padding: 8px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.store-button-v2-default:focus {
  outline: none;
}

.store-button-v2-default:hover {
  background: #eaebec;
}

@media (max-width: 1279px) {
  .store-button-v2-default {
    min-width: 0;
    max-width: 73px;
    border-radius: 20px;
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    height: 64px;
    width: 73px;
  }
}
