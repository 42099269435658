.serv-cliente {
  background: #ffffff;
  min-height: 400px;
  color: #200e3b !important;
}
.sc-img {
  width: 50px;
  height: 50px;
}

.schecudle-info {
  height: 25px;
  font: normal normal normal 18px;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
}

.contact-container {
  width: 100%;
  padding-left: 134px;
  padding-right: 140px;
  display: flex;
  justify-content: space-between;
}

.contact-info-text {
  height: 25px;
  text-align: left;
  text-decoration: underline;
  font: normal normal normal 1.5em OpenSans-Regular;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.contact-info-text-v2 {
  height: 25px;
  text-align: left;
  text-decoration: underline;
  font: normal normal normal 1.5em OpenSans-Regular;
  letter-spacing: 0px;
  color: #200e3b;
  opacity: 1;
}

.icon-contact-webtres {
  width: 60px;
  height: 60px;
  padding-right: 10px;
}

.schedule-info-v2 {
  height: 25px;
  letter-spacing: 0px;
  color: #200e3b;
  opacity: 1;
  font-size: 15px !important;
}

.serv-cliente .business-contacts .contact-info {
  margin-right: 5em;
}

.serv-cliente-compact .business-contacts .contact-info {
  flex-direction: column;
  margin-right: 3rem;
}

@media (min-width: 1900px) {
  .serv-cliente .business-contacts .contact-info .icon-contact-webtres {
    height: 80px;
    width: 80px;
  }
  .contact-info-text {
    font-size: 2em;
  }
}

.serv-cliente-compact {
  min-height: initial !important;
  width: 100%;
  background-color: transparent !important;
}

.serv-cliente-compact .contact-info-text {
  color: #3a2855 !important;
}

.serv-cliente-compact .contact-info-text-v2 {
  color: #200e3b !important;
}
.business-compact {
  margin: 0 !important;
  padding: 0 !important;
  justify-content: center !important;
}

@media only screen and (max-width: 800px) {
  .serv-cliente-compact > div {
    padding-top: 0rem !important;
  }
  .business-compact > a,
  .business-compact > div {
    width: 50%;
    margin: 0 !important;
  }
  .serv-cliente-compact .business-contacts .contact-info {
    margin-right: 0 !important;
  }
  .serv-cliente-compact > div {
    padding-bottom: 0 !important;
  }
}
