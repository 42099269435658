.bannerContainer {
  width: 100%;
}

.bannerImage {
  display: block;
  max-width: 1200px;
  height: auto;
  margin: 0 auto;
  width: 100%;
  position: relative;
}

.activeZone {
  display: block;
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 700px) {
  .bannerImage {
    height: auto;
  }
  .activeZone {
    height: auto;
  }
}
