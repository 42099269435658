.loading-confirmation-frame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-confirmation-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  gap: 12px;
}

.loading-confirmation-title {
  width: 396px;

  font-family: 'OpenSans-Bold';
  font-style: normal;

  font-size: 32px;
  line-height: 48px;
}

.loading-confirmation-buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
}

.loading-confirmation-primary-button {
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 146px;
  height: 44px;

  /* Primarios/Morado oscuro */
  border: 1px solid #200e3b;
  /* Shadow/xs */
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
  border-radius: 4px;
}

.loading-confirmation-secondary-button {
  width: 146px;
  height: 44px;
}

.div-margin-top {
  margin-top: 30px;
}

@media (max-width: 764px) {
  .loading-confirmation-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 16px;
    gap: 12px;
  }

  .loading-confirmation-title {
    width: 100%;
    font-size: 24px;
    line-height: 36px;
  }

  .div-margin-top {
    margin-top: 0;
  }

  .loading-confirmation-buttons {
    width: 100%;
  }

  .loading-confirmation-primary-button {
    width: 100%;
  }

  .loading-confirmation-secondary-button {
    width: 100%;
  }
}
