.card-cenco {
  box-sizing: content-box;
  width: 96.3%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 1rem;
  background: #ffffff;
  border: 1px solid #dbdee5;
  border-radius: 8px;
  font-family: 'OpenSans-Medium';
}

.card-cenco-title-container {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
}
.card-cenco-body-container {
}
.card-cenco-footer-container {
  display: flex;
  align-items: baseline;
}

.card-cenco-title {
  margin-left: 5px;
  color: #667085;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}
