.fidcl-footer-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  background-color: #333333;
  color: #ffffff;
  padding-top: 40px;
  padding-left: 32px;
  padding-right: 32px;
}

.fidcl-footer-columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 150px;
  padding: 20px 130px 20px 130px;
}

.fidcl-footer-column {
  text-align: start;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
}

.fidcl-footer-column-title {
  font-family: OpenSans-Bold;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
}

.fidcl-footer-column-section {
  font-family: OpenSans-Regular;
  font-size: 16px;
  line-height: 21px;
}

.fidcl-footer-container-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 0 16px 0;
}
.fidcl-footer-container-social-img {
  width: 24px;
}

.fidcl-footer-container-logo-title {
  font-family: OpenSans-Regular;
  font-size: 16px;
  line-height: 24px;
}

.fidcl-footer-container-logo-title-divider {
  width: 120%;
  position: relative;
  margin: 16px 0 16px 0;
  border-bottom: 1px solid #6f6f6f;
}
.fidcl-footer-container-logo-subtitle {
  font-family: OpenSans-Regular;
  font-size: 16px;
  line-height: 15px;
  display: flex;
  gap: 12px;
}

.fidcl-footer-column-link,
.fidcl-footer-column-link:focus
  .fidcl-footer-column-link:hover
  .fidcl-footer-column-link
  > a {
  color: #ffffff;
  display: flex;
  gap: 8px;
}

.fidcl-footer-column-link:hover {
  color: #e1e1e1;
  outline: none;
}

@media only screen and (max-width: 1279px) {
  .fidcl-footer-columns {
    flex-direction: column;
    padding: 0;
    gap: 0;
  }
  .fidcl-footer-column {
    gap: 12px;
  }

  .fidcl-footer-column-section-special {
    display: flex;
    gap: 12px;
  }

  .fidcl-footer-container {
    padding-left: 16px;
    padding-right: 16px;
    gap: 16px;
  }

  .fidcl-footer-column-title {
    font-size: 16px;
  }
  .fidcl-footer-container-logo-subtitle {
    text-align: center;
    font-size: 13.5px;
  }
  .fidcl-footer-container-logo {
    padding: 0 0 16px 0;
  }
  .fidcl-footer-container-social-img {
    width: 32px;
  }
}
