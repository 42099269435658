.container-points {
  display: flex;
  justify-content: flex-start;
}
.container-detail-points {
  max-width: 300px;
  margin: 30px 0px 0px -20px;
}

.text-store-cenco-puntos {
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  color: #783184;
}
.text-store-cenco-puntos-prime {
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  color: #200e3b;
}

.size-puntos-percent {
  font-size: 14px;
}

.badge-percent-off {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 10px 0px 10px;
  width: 40px;
  height: 24px;
  background: #ebe0ed;
  border-radius: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 2px;
}

.strikethrough-container {
  display: flex;
  justify-content: flex-start;
  padding-right: 30%;
  color: #6f6f6f;
}

.strikethrough {
  text-decoration-line: line-through;
  font-weight: 600 !important;
}

.strikethrough-detail {
}

.img-puntos {
  margin-left: 4px;
  margin-right: 2px;
  padding-bottom: 8px;
  width: 20px;
}
.img-strikethrough-puntos {
  margin-left: 4px;
  margin-right: 4px;
  padding-bottom: 8px;
  width: 20px;
}
.img-puntos-prime {
  width: 50px;
}

.container-main-image-product {
  height: 0;
  position: relative;
  padding-bottom: 60%;
  display: flex;
}

.image-product-container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-product {
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

@media (max-width: 767px) {
  .container-detail-points {
    max-width: 300px;
    margin: 30px 0px 0px -10px;
  }
  .badge-percent-off {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 2px 0px 2px;
    width: 32px;
    height: 24px;
    background: #ebe0ed;
    border-radius: 16px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-right: 2px;
  }
  .img-puntos {
    padding-bottom: 8px;
    width: 15px;
  }
  .img-puntos-prime {
    width: 40px;
  }
  .img-strikethrough-puntos {
    width: 15px;
  }
}
