.fidcl-badge__text {
  font-size: 1.2rem;
  font-weight: 200;
  color: #fff;
}

.fidcl-badge--new {
  background-color: hsl(270.4, 43.6%, 32%);
}

.fidcl-badge--offert {
  background-color: hsl(325.4, 59.2%, 52.9%);
}
