.cenco-points-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.cenco-prime-points {
  display: flex;
  gap: 1.5px;
  font-family: OpenSans-Bold;
  font-size: 16px;
  line-height: 21px;
  color: #783184;
  min-height: 24px;
}
.cenco-prime-points.out-stock {
  color: rgba(102, 112, 133, 1);
}
.cenco-normal-points {
  display: flex;
  gap: 1.5px;
  font-family: OpenSans-Bold;
  font-size: 16px;
  line-height: 21px;
  color: #783184;
  min-height: 24px;
}

.cenco-normal-points.out-stock {
  color: rgba(102, 112, 133, 1);
}

.cenco-strike-points {
  display: flex;
  gap: 1.5px;
  font-family: OpenSans-SemiBold;
  font-size: 14px;
  line-height: 21px;
  color: #6f6f6f;
  height: 21px;
  text-decoration: line-through;
}
.strikethrough {
  text-decoration-line: line-through;
}

.badge-normal-point-percent-off {
  background: #eaebec;
  color: #200e3b;
  padding: 2px 4px;
  font-size: 12px;
}

.badge-prime-point-percent-off {
  background: #ebe0ed;
  color: #200e3b;
  padding: 2px 4px;
  font-size: 12px;
}

.badge-normal-point-percent-off.out-stock {
  color: rgba(102, 112, 133, 1);
  background: rgba(234, 235, 236, 1);
}

.badge-prime-point-percent-off.out-stock {
  color: rgba(102, 112, 133, 1);
  background: rgba(234, 235, 236, 1);
}

.symbol-points {
  font-family: OpenSans-SemiBold;
  font-size: 16px;
  line-height: 21px;
  color: #212529;
  margin-right: 2px;
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 0;
}

.symbol-points.out-stock {
  color: rgba(102, 112, 133, 1);
}

.copay-points {
  font-family: OpenSans-SemiBold;
  font-size: 16px;
  line-height: 21px;
  color: #212529;
}

.copay-points.out-stock {
  color: rgba(102, 112, 133, 1);
}

.points-lenght-less-13-container {
  display: flex;
  width: 100%;
}

.points-lenght-less-13 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  gap: 2px;
  width: 100%;
  align-items: center;
}

.points-lenght-plus-13-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.points-lenght-plus-13 {
  display: flex;
  flex-direction: row;
  gap: 2px;
  width: 100%;
}

.points-for-money-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 2px;
}

.points-for-money-prefix {
  font-family: 'OpenSans-SemiBold';
  font-style: normal;
  font-size: 16px;
  line-height: 21px;
  color: #212529;
}

.points-for-money-number {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 2px;
  font-family: 'OpenSans-Bold';
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
}
