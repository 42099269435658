.login-form-container-view {
  border: 1px solid rgb(219, 222, 229);
  border-radius: 12px;
  width: 500px;
}

.header-login {
  width: 100%;
}

.first-title-login {
  color: #212529;
  font-family: OpenSans-Bold !important;
  font-size: 24px;
  font-style: normal;
  line-height: 36px;
}

.second-title-login {
  color: #212529;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
}

.forget-password {
  text-align: right;
  margin-bottom: 20px;
}

.footer-login {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 16px;
}

.footer-login-btn-reg {
  width: 100%;
}

.footer-login-btn-log {
  width: 100%;
  white-space: nowrap;
}

@media only screen and (max-width: 768px) {
  .footer-login {
    display: flex;
    flex-direction: column-reverse;
  }
  .footer-login-btn-reg {
    margin-left: 0px;
    margin-right: 0px;
  }
  .footer-login-btn-log {
    margin-left: 0px;
    margin-right: 0px;
  }
  .login-form-container-view {
    border: none;
    width: 100%;
  }
  .first-title-login {
    font-size: 20px;
    line-height: 30px;
  }
  .second-title-login {
    font-size: 16px;
    line-height: 24px;
  }
}
