.edit-field-in-line svg {
  cursor: pointer;
  color: #5b2b75;
  width: 18px;
  margin-right: 8px;
  margin-top: -8px;
}

.edit-field-in-line #email svg {
  margin-top: 6px;
}

.edit-field-in-line input:disabled {
  background-color: white;
}

label {
  font-size: 1.4rem;
}

.form-control {
  position: relative !important;
}

.fi-icon-editable {
  padding-top: 5px;
}
