.fullpage-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 0;
}

.fullpage {
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 0;
  padding-top: 109px;
}

@media only screen and (max-width: 1279px) {
  .fullpage {
    padding-top: 189px;
  }
}
