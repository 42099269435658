.fidcl-alert {
  position: relative;
  padding: 1.5rem;
  border-radius: 0.4rem;
  overflow: hidden;
  margin-bottom: 1.5rem;
  font-size: 1.4rem;
}

.fidcl-alert-with-icon {
  padding-left: 4.5rem;
  vertical-align: center;
  margin-bottom: 0 !important;
}

.fidcl-alert-with-icon .fidcl-alert--icon {
  position: absolute;
  left: 1.5rem;
  /*top: 2.3rem;*/
}

.fidcl-alert-with-icon .fidcl-alert--icon svg {
  width: 2.2rem;
}

.fidcl-alert > .fidcl-button {
  margin-top: 0.75rem;
}

.fidcl-alert-fix > div > div {
  margin: 0;
}

.fidcl-alert a {
  text-decoration: underline !important;
}

.fidcl-alert-full-w {
  justify-content: center;
  flex-direction: column;
  display: flex;
  width: 100%;
}
