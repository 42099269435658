.submenu-container {
  position: absolute;
  top: 0;
  left: 400px;
  width: 0;
  overflow-x: hidden;
  z-index: 5;
  height: calc(100vh - 80px);
  font-size: 18px;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  overscroll-behavior: contain;
}

.submenu-container-fixed-down {
  height: calc(100vh - 80px);
}

.submenu-container-fixed-up {
  height: calc(100vh - 109px);
}

.submenu-container.opened {
  left: 400px;
  width: 400px;
  padding: 30px 16px;
}

.submenu-header {
  font-size: 20px;
  font-style: normal;
  font-family: OpenSans-SemiBold;
  line-height: 150%;
  margin-bottom: 12px;
}

.button-back-sublist-menu {
  display: none;
}

.submenu-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.submenu-list {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
}

.submenu-list li button {
  color: #212529;
  font-size: 18px;
  font-family: OpenSans-Medium;
  font-style: normal;
  line-height: 27px;
}

.submenu-list-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.divider-subcategory {
  width: 100% !important;
}

@media only screen and (max-width: 1279px) {
  .submenu-container {
    left: 0px;
    background-color: #f3f2f7;
    box-shadow: none;
    border-top: 26px solid #783184;
  }
  .submenu-container.opened {
    width: 100%;
    left: 0;
    z-index: 20;
    padding: 30px 8px 110px 8px;
  }

  .submenu-container-fixed-down {
    height: 100vh;
  }

  .submenu-container-fixed-up {
    height: 100vh;
  }

  .submenu-list ul li {
    font-size: 18px;
    font-family: OpenSans-Medium;
    font-style: normal;
    line-height: 27px;
  }
  .button-back-sublist-menu {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
  }
  .view-all-button-subcategory {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
  }
  .submenu-header {
    padding-left: 2px;
    padding-right: 6px;
  }
}
