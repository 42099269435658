/* 
 * Exchange
 */

.btn-ppc {
  height: 60px !important;
  border-radius: 50px !important;
  opacity: 1 !important;
  font-family: OpenSans-Regular !important;
  font-weight: bold !important;
  font-size: 30px !important;
  width: auto !important;
}

.btn-ppc > p {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 25px !important;
}

.btn-ppc:focus {
  outline: 0 !important;
  box-shadow: none;
}

.btn-icon {
  height: 41px;
  width: 41px;
}

.btn-icon-mobile {
  height: 55px;
  width: 55px;
}

.btn-exchange {
  font-size: 20px;
  /*background: #2DC850 0% 0% no-repeat padding-box;*/
  background: #69b727 0% 0% no-repeat padding-box;
  color: #ffffff;
  border-radius: 10px;
  opacity: 1;
}

.exchange-header-text {
  text-align: left;
  font: normal normal 900 30px/41px OpenSans-Regular;
  letter-spacing: 0px;
  color: #2c2c2c;
  opacity: 1;
}

.br-10 {
  border-radius: 10px !important;
}

.br-20 {
  border-radius: 20px !important;
}
.br-30 {
  border-radius: 30px !important;
}

.bg-easy {
  background: #e30903;
}

.bg-paris {
  background: #006dff;
}

.bg-jumbo {
  /*background: #2DC850;*/
  background: #69b727;
}

.bg-santa-isabel {
  background: #e2061c;
}

.bg-point {
  background: #532674;
}

.text-point {
  color: #802584;
}
.mb-6 {
  margin-bottom: 4em;
}
.mb-7 {
  margin-bottom: 5em;
}
.mb-8 {
  margin-bottom: 3em;
}
.max-h-40 {
  max-height: 50px;
}

.min-h-40 {
  min-height: 40px;
}

.min-h-30 {
  min-height: 42px;
}

.h4-5 {
  font-size: 1.4rem;
  font-weight: 700;
}

.mb-3px {
  margin-bottom: 3px;
}

.max-h-30 {
  max-height: 42px;
}

.fs-3em {
  font-size: 2em;
}

.icon-arrow-left {
  transform: rotate(-90deg);
  width: 4em;
}

.icon-arrow-right {
  transform: rotate(90deg);
  width: 4em;
}
.position-icon {
  position: relative;
  bottom: 8em;
}

.font-size-18 {
  font-size: 18px;
}

.min-h-150 {
  min-height: 150px;
  max-height: 150px;
}

.content-banner {
  font-size: 3em;
  position: absolute;
  color: white;
  width: 100%;
  height: 90%;
}

.product-image {
  max-width: 100%;
  max-height: 100%;
  height: 150px !important;
}

.w-13em {
  width: 13em;
}

.bg-point-product {
  background-color: #802584;
}

.pl-35 {
  padding-left: 35px;
}
.pr-35 {
  padding-right: 35px;
}

.mt-6 {
  margin-top: 4rem !important;
}
.mt-7 {
  margin-top: 5rem !important;
}

.mb-7em {
  margin-bottom: 7em;
}
.without-border:focus {
  box-shadow: none;
}

.generic-product-text {
  text-overflow: ellipsis;
  overflow: hidden;
}

.mt-1-2 {
  margin-top: 0.15rem;
}

div.strike-through {
  text-decoration: line-through #e72470 3px !important;
  text-decoration-line: line-through !important;
  text-decoration-color: #e72470 !important;
  text-decoration-style: solid !important;
  text-decoration-thickness: 3px !important;
  -webkit-text-decoration-line: line-through !important;
  -webkit-text-decoration-color: #e72470 !important;
  -webkit-text-decoration-style: solid !important;
  -webkit-text-decoration-thickness: 3px !important;
}

.classToIos {
  text-decoration: line-through #e72470 3px !important;
  text-decoration-line: line-through !important;
  text-decoration-color: #e72470 !important;
  text-decoration-style: solid !important;
  text-decoration-thickness: 3px !important;
  -webkit-text-decoration-line: line-through !important;
  -webkit-text-decoration-color: #e72470 !important;
  -webkit-text-decoration-style: solid !important;
  -webkit-text-decoration-thickness: 3px !important;
}
