.btn-puntos-cenco {
  padding: 0 12px;
  align-items: center;
  border-radius: 8px;
  background: #200e3b;
  color: #ffffff;
  border: 1px solid #200e3b;
  font-style: normal;
  font-family: 'OpenSans-SemiBold';
  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;
  white-space: nowrap;
}

.btn-puntos-cenco-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fid-button-xs {
  height: 32px;
}
.fid-button-sm {
  height: 41px;
}
.fid-button-md {
  height: 44px;
}
.fid-button-lg {
  height: 48px;
}
.fid-button-xl {
  height: 59px;
}

.btn-puntos-cenco:disabled {
  background: #6c757d;
  border-color: #6c757d;
}
.btn-puntos-cenco:hover:not([disabled]) {
  background: #413258;
  border-color: #413258;
}
.btn-readonly {
  cursor: not-allowed !important;
  cursor: none;
  pointer-events: none !important;
}
.btn-puntos-cenco:focus {
  background: #200e3b;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.25), 0px 0px 0px 4px #f4ebff;
  outline: none;
}

.btn-puntos-cenco-secondary-a {
  color: #200e3b;
  background: #ffffff00;
  border-color: #200e3b;
}
.btn-puntos-cenco-secondary-a:disabled {
  color: #c4c5c9;
  border-color: #ffffff;
  background: #ffffff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}
.btn-puntos-cenco-secondary-a:hover:not([disabled]) {
  background: #c4c5c9;
  border-color: #200e3b;
}

.btn-puntos-cenco-secondary-a:focus {
  background: #ffffff;
  border-color: #200e3b;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.25), 0 0 0 4px #dbdee5;
  outline: none;
}

.btn-puntos-cenco-secondary-b {
  color: #ffffff;
  background: #200e3b;
  border-color: #ffffff;
}
.btn-puntos-cenco-secondary-b:disabled {
  color: #667085;
  border-color: #667085;
  background: #200e3b;
}
.btn-puntos-cenco-secondary-b:hover:not([disabled]) {
  color: #200e3b;
  background: #dbdee5;
  border-color: #200e3b;
}
.btn-puntos-cenco-secondary-b:focus {
  color: #200e3b;
  border-color: #200e3b;
  background: #ffffff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.25), 0 0 0 4px #dbdee5;
  outline: none;
}

.btn-puntos-cenco-tertiary {
  color: #ffffff;
  background: #783184;
  border-color: #783184;
}
.btn-puntos-cenco-tertiary:disabled {
  background: #6c757d;
  border-color: #6c757d;
}
.btn-puntos-cenco-tertiary:hover:not([disabled]) {
  background: #562e5c;
  border-color: #562e5c;
}
.btn-puntos-cenco-tertiary:focus {
  background: #783184;
  border-color: #783184;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.25), 0 0 0 4px #f4ebff;
  outline: none;
}

.btn-santa {
  background: #c92228 !important;
  border-color: #c92228 !important;
}
.btn-jumbo {
  background: #2dc850 !important;
  border-color: #2dc850 !important;
}
.btn-easy {
  background: rgb(227, 9, 23) !important;
  border-color: rgb(227, 9, 23) !important;
}
.btn-paris {
  background: #006dff !important;
  border-color: #006dff !important;
}
