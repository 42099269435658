.empty-failed-exchange-container {
  /* Empty state_Mis Canjes */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 24px;

  position: sticky;
  width: 768px;
  height: 295px;
  left: calc(50% - 768px / 2);
  top: 150px;

  /* Badges/Gray */
  background: #f2f4f7;
  border-radius: 8px;
}

.empty-failed-exchange-title {
  width: 471px;
  height: 27px;

  /* Subtitle/Subt-2-Bold */
  font-family: 'OpenSans-SemiBold';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height, or 150% */

  /* Neutrales/Gray 1000 */
  color: #212529;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.empty-failed-exchange-sub-title {
  height: 24px;

  /* Body/Body-1-Medium */
  font-family: 'OpenSans-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  text-align: center;

  /* Neutrales/Gray 1000 */
  color: #212529;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.see-failed-exchange-button {
  /* Botón */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  gap: 8px;

  width: 161px;
  height: 44px;

  /* Primarios/Morado oscuro */
  background: #200e3b;
  /* Primarios/Morado oscuro */
  border: 1px solid #200e3b;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;

  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;
}

.div-margin-top {
  margin-top: 30px;
}

@media (max-width: 767px) {
  .empty-failed-exchange-container {
    /* Empty state_Mis Canjes */
    width: 343px;
    height: 322px;
    left: calc(50% - 343px / 2);
  }

  .see-failed-exchange-button {
    /* Botón */
    /* Auto layout */
    display: flex;
    padding: 10px 16px;

    width: 100%;
    height: 41px;

    /* Inside auto layout */
    order: 2;
    align-self: stretch;
  }

  .empty-failed-exchange-title {
    /* Tenemos problemas con el canje que deseas realizar */

    width: 303px;
    height: 54px;
    text-align: center;

    /* Inside auto layout */
    align-self: stretch;
  }

  .empty-failed-exchange-sub-title {
    /* Por favor intenta de nuevo más tarde. */
    width: 303px;
    height: 24px;
  }
}
