.info-card {
  /* _Card-info */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;

  /* Neutrales/White */
  background: #ffffff;
  /* Neutrales/Bordes */
  border: 1px solid #dbdee5;
  border-radius: 10px;
}

.info-card-exchange-detail {
  align-items: flex-start;
  padding: 16px 12px;
}

.specification-details {
  width: 100%;
}

.linea-divisoria-product-detail-footer {
  height: 1px;
  background-color: #dbdee5;
  border-radius: 4px;
  margin: 20px 0;
}

.info-card-exchange-conditions {
  padding: 16px 12px;
  width: 100%;
}

.title-detail-specification {
  font-family: OpenSans-Bold;
  color: #212529 !important;
  font-size: 18px !important;
  line-height: 27px !important;
}

.text-detail-canje,
.li-condiciones-canje {
  font-size: 18px !important;
  color: #212529 !important;
  line-height: 27px !important;
}

.text-detail-canje a,
.li-condiciones-canje a {
  text-decoration: underline;
}

.product-detail-tab-panel {
  /* Frame 1000003703 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 20px;
}

.data-break {
  word-wrap: break-word !important;
  white-space: unset !important;
  overflow-wrap: break-word;
  word-break: break-word;
}

@media (max-width: 768px) {
  .info-card {
    justify-content: center;
  }

  .info-card-exchange-detail {
    align-items: center;

    width: 100%;
  }

  .title-detail-specification {
    font-size: 16px !important;
    line-height: 24px !important;
  }

  .text-detail-canje,
  .li-condiciones-canje {
    font-size: 16px !important;
    line-height: 24px !important;
  }

  .linea-divisoria-product-detail-footer {
    margin: 16px 0;
  }

  .info-card-exchange-conditions {
    width: 100%;
  }
}
